<!--区域运力统计-->
<template>
  <div ref="element" style="height: 100vh;position: relative;">
    <div v-if="showEdit === false && showPricingformula === false">
      <div id="container" style="height: 100vh;">
        <!--<AMapLoader v-if="lazyLoading === true" :msgData="msgData" v-bind:locationorigin="locationorigin" :locationdestination="locationdestination" :driver="driver"></AMapLoader>-->
        <div class="info" style="display: none;">
          <div id="centerCoord"></div>
          <div id="tips"></div>
        </div>
        <input id="setFitView" type="button" class="btn" value="地图自适应显示" style="display: none;"/>
      </div>
      <div class="yunlitongjicontent">
        <div class="content1yunlitongji">
          <div class="content_inputyunlitongji" style="width: 316px;">
            <el-input
                placeholder="请输入服务商名称"
                v-model="keyword"
                @blur="keywordquery(0)"
                clearable>
            </el-input>
          </div>

          <div class="content_input5yunlitongji">
            <div style="display: flex;justify-content: space-around;">
              <div style="display: flex;align-items: center;">
                <!--<img src="@/assets/images/home/yunli/didian.png" alt="" style="width: 15px;height: 19px;margin-right: 10px;">-->
                <!--<span style="font-size: 16px;font-weight: 500;color: #3f4157;">全国</span>-->
                <!--<el-cascader-->
                <!--  clearable-->
                <!--  v-model="value"-->
                <!--  placeholder="请选择地区"-->
                <!--  :options="city"-->
                <!--  @change="handleChange"></el-cascader>-->
                <el-cascader
                    v-model="value"
                    :options="city"
                    :props="{ checkStrictly: true }"
                    @change="handleChange"
                    clearable></el-cascader>
              </div>
              <!--<div>-->
              <!--  <img src="@/assets/images/home/yunli/xiala.png" alt="" style="width: 12px;height: 7px;">-->
              <!--</div>-->
            </div>
          </div>

          <!--<div class="content_input1">-->
          <!--  <div style="display: flex;justify-content: space-around;">-->
          <!--    <div style="display: flex;align-items: center;">-->
          <!--      <img src="@/assets/images/home/yunli/didian.png" alt="" style="width: 15px;height: 19px;margin-right: 10px;">-->
          <!--      <span style="font-size: 16px;font-weight: 500;color: #3f4157;">所有项目</span>-->
          <!--    </div>-->
          <!--    <div>-->
          <!--      <img src="@/assets/images/home/yunli/xiala.png" alt="" style="width: 12px;height: 7px;">-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</div>-->

          <div class="content_inputyunlitongji" style="margin-left: 20px;">
            <el-input
                placeholder="请输入姓名/电话"
                v-model="keyword1"
                @blur="keywordquery(1)"
                clearable>
            </el-input>
          </div>

          <div class="content_input1yunlitongji" @click="justsee(1)">
            <div style="text-align: center;">
              <!--<img src="@/assets/images/home/yunli/siji.png" alt="" style="width: 14px;height: 16px;margin-right: 10px;vertical-align: middle;">-->
              <!--<span style="font-size: 16px;font-weight: 500;color: #3f4157;vertical-align: middle;">只看司机</span>-->
              <el-checkbox v-model="checked">
                <span style="color:#fff;">只看司机</span>
              </el-checkbox>
            </div>
          </div>
          <div class="content_input1yunlitongji" @click="justsee(0)">
            <div style="text-align: center;">
              <!--<img src="@/assets/images/home/yunli/fuwushang.png" alt="" style="width: 14px;height: 16px;margin-right: 10px;vertical-align: middle;">-->
              <!--<span style="font-size: 16px;font-weight: 500;color: #3f4157;vertical-align: middle;"></span>-->
              <el-checkbox v-model="checked1">
                <span style="color: #fff">只看服务商</span>
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div>-->
    <!--  <div class="content2" v-if="isShow === 0">-->
    <!--    <div>-->
    <!--      <div style="font-size: 14px;font-weight: 400;color: #a8a8a8;">请选择城市</div>-->
    <!--      <div style="margin-top: 15px;" class="shoufengqin">-->
    <!--        <div v-for="(item,index) in list" :key="index" class="xuanze" @click="clickactive(item,index)">-->
    <!--          <span>{{item.label}}</span>-->
    <!--          <span style="margin-left: 15px;">服务商</span>-->
    <!--          <span>({{item.facilitatorCount}})</span>-->
    <!--          <span style="margin-left: 10px;">司机</span>-->
    <!--          <span>({{item.driverCount}})</span>-->
    <!--          <span style="margin-left: 10px;">订单量</span>-->
    <!--          <span>({{item.orderCount}})</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="content2" v-if="isShow === 1">-->
    <!--    <div>-->
    <!--      <div style="font-size: 14px;font-weight: 400;color: #a8a8a8;display: flex;justify-content: space-between;">-->
    <!--        <span>请选择城市</span>-->
    <!--        <span style="cursor: pointer;" @click="returnstep(0)">返回</span>-->
    <!--      </div>-->
    <!--      <div style="margin-top: 15px;" class="shoufengqin">-->
    <!--        <div v-for="(item,index) in children" :key="index" class="xuanze" @click="changechildren(item,index)">-->
    <!--          <span>{{item.label}}</span>-->
    <!--          <span style="margin-left: 15px;">服务商</span>-->
    <!--          <span>({{item.facilitatorCount}})</span>-->
    <!--          <span style="margin-left: 10px;">司机</span>-->
    <!--          <span>({{item.driverCount}})</span>-->
    <!--          <span style="margin-left: 10px;">订单量</span>-->
    <!--          <span>({{item.orderCount}})</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="content3" v-if="isShow === 2">-->
    <!--    <div>-->
    <!--      <div class="shoufengqin">-->
    <!--        <div style="font-size: 14px;font-weight: 400;color: #a8a8a8;display: flex;justify-content: flex-end;margin-bottom: 20px;">-->
    <!--          <span style="cursor: pointer;" @click="returnstep(1)">返回</span>-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          <div>-->
    <!--            <div v-for="(item,index) in gongsiList" :key="index" class="gongsiliebiao" :class="{gongsiliebiao1:index==pitchon}"  @click="openShow(item,index)">-->
    <!--              <div>-->
    <!--                <div>{{item.company_name}}</div>-->
    <!--                <div>{{item.detailed_address}}</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;<div>&ndash;&gt;-->
    <!--          &lt;!&ndash;  <el-pagination&ndash;&gt;-->
    <!--          &lt;!&ndash;    background&ndash;&gt;-->
    <!--          &lt;!&ndash;    @size-change="handleSizeChange"&ndash;&gt;-->
    <!--          &lt;!&ndash;    @current-change="handleCurrentChange"&ndash;&gt;-->
    <!--          &lt;!&ndash;    :current-page="currentPage"&ndash;&gt;-->
    <!--          &lt;!&ndash;    :page-sizes="[10, 20, 50, 100]"&ndash;&gt;-->
    <!--          &lt;!&ndash;    :page-size="100"&ndash;&gt;-->
    <!--          &lt;!&ndash;    layout="total, sizes, prev, pager, next, jumper"&ndash;&gt;-->
    <!--          &lt;!&ndash;    :total="total">&ndash;&gt;-->
    <!--          &lt;!&ndash;  </el-pagination>&ndash;&gt;-->
    <!--          &lt;!&ndash;</div>&ndash;&gt;-->
    <!--        </div>-->
    <!--        <div v-if="gongsiList.length == 0">-->
    <!--          <el-empty description="暂无数据"></el-empty>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="tanchuang" v-if="show">-->
    <!--    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">-->
    <!--      <span style="font-size: 18px;font-weight: 500;color: #ffffff;">{{fuwushang.company_name}}</span>-->
    <!--    </div>-->
    <!--    <div class="tanchuang_b">-->
    <!--      <el-row :gutter="15" style="margin-bottom: 10px;">-->
    <!--        <el-col :span="6">-->
    <!--          <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>-->
    <!--        </el-col>-->
    <!--        <el-col :span="18">-->
    <!--          <span style="font-size: 14px;font-weight: 400;color: #ffffff;">{{fuwushang.phone}}</span>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      <el-row :gutter="15" style="margin-bottom: 10px;">-->
    <!--        <el-col :span="6">-->
    <!--          <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>-->
    <!--        </el-col>-->
    <!--        <el-col :span="18">-->
    <!--          <span style="font-size: 14px;font-weight: 400;color: #ffffff;">{{fuwushang.detailed_address}}</span>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--      &lt;!&ndash;<el-row :gutter="15" style="margin-bottom: 10px;">&ndash;&gt;-->
    <!--      &lt;!&ndash;  <el-col :span="6">&ndash;&gt;-->
    <!--      &lt;!&ndash;    <span style="font-size: 14px;font-weight: 400;color: #ffffff;">合作项目：</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;  </el-col>&ndash;&gt;-->
    <!--      &lt;!&ndash;  <el-col :span="18">&ndash;&gt;-->
    <!--      &lt;!&ndash;    <span style="font-size: 14px;font-weight: 400;color: #ffffff;">&ndash;&gt;-->
    <!--      &lt;!&ndash;      <span style="margin-right: 10px;">拖车</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <span style="margin-right: 10px;">搭电</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <span style="margin-right: 10px;">换胎</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;    </span>&ndash;&gt;-->
    <!--      &lt;!&ndash;  </el-col>&ndash;&gt;-->
    <!--      &lt;!&ndash;</el-row>&ndash;&gt;-->
    <!--      <div style="margin-top: 20px;text-align: center;">-->
    <!--        <el-button type="primary" @click="openPricingformula">添加计价公式</el-button>-->
    <!--        <el-button type="primary" @click="openDetails">设置接单规则</el-button>-->
    <!--        <el-button type="danger" plain @click="show = false">关闭</el-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->


  <!--  服务商弹窗-->
  <!--  <div style="width: 25%;position: absolute;top: 30%;left: 40%;margin: -50px 0 0 -50px;">-->
  <!--    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">-->
  <!--      <span style="font-size: 18px;font-weight: 500;color: #ffffff;">123456</span>-->
  <!--    </div>-->
  <!--    <div style="background:rgba(127,127,127,0.8);padding: 20px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">-->
  <!--      <div>-->
  <!--        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>-->
  <!--        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">123456</span>-->
  <!--      </div>-->
  <!--      <div style="margin-top: 10px;">-->
  <!--        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>-->
  <!--        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">河北省邯郸市魏县</span>-->
  <!--      </div>-->
  <!--      <div style="margin-top: 20px;text-align: center;display: flex;justify-content: space-between;">-->
  <!--        <div id="guanbi" style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">添加计价公式</div>-->
  <!--        <div id="guanbi" style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">设置接单规则</div>-->
  <!--        <div id="guanbi" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">关闭</div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--</div>-->


    <!--司机弹窗-->
    <!--position: absolute;top: 110px;left: 800px;-->
    <!--<div id="chuangtidiv" ref="chuangtidiv" style="width:300px; background: #FFFFFF;padding: 20px;" v-if="chuangdiv">-->
    <!--  <div style="display: flex;justify-content: space-between;align-items: center; margin-bottom: 10px;">-->
    <!--    <span>-->
    <!--      <i class="el-icon-user-solid"></i>-->
    <!--      <span>-</span>-->
    <!--      <span style="margin-left: 8px;">离线</span>-->
    <!--    </span>-->
    <!--    <span>冀D00000</span>-->
    <!--  </div>-->
    <!--  <div style="padding-bottom: 10px; border-bottom: 1px dashed #409EFF;">-->
    <!--    道路救援公司-->
    <!--  </div>-->
    <!--  <div style="margin-top: 10px;">-->
    <!--    <span>联系电话：</span>-->
    <!--    <span>13100000000</span>-->
    <!--  </div>-->
    <!--  <div style="margin-top: 10px;">-->
    <!--    <span>在线时长：</span>-->
    <!--    <span>-</span>-->
    <!--  </div>-->
    <!--  <div style="margin-top: 10px;">-->
    <!--    <span>服务里程/时长：</span>-->
    <!--    <span>-km/-</span>-->
    <!--  </div>-->
    <!--  <div style="margin-top: 10px;">-->
    <!--    <span>位置更新：</span>-->
    <!--    <span>2022-06-22 19:07:55</span>-->
    <!--  </div>-->
    <!--  <div style="margin-top:15px;">-->
    <!--    <div id="guanbi" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width: 50%;margin: auto;">-->
    <!--      关闭窗口-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->

    <!--设置派单规则-->
    <Details v-if="showEdit === true" @showDetails="showdetails"/>

    <!--设置计价公式-->
    <Pricingformula  v-if="showPricingformula === true" @showPricingformula="showpricingformula"/>

  </div>

</template>

<script>
  //引入设置派单规则
  import Details from './details.vue'
  //引入设置计价公式
  import Pricingformula from './Pricingformula.vue'
  // 引入的接口
  import {get_driver_coord, get_range_statistics, nationwide_facilitator, second_level_city,getcityList} from "@/api/yunli";

  // 引入高德地图
  import AMapLoader from '@amap/amap-jsapi-loader';
  //获取高德地图的key
  import setting from '@/config/config'


  export default {
    components:{
      Details,
      Pricingformula
    },
    data(){
      return{
        name:'',
        activeName: '1',
        // 省份
        list:[],
        // 市
        children:[],
        // 服务商
        gongsiList:[],
        // 当前显示的块
        isShow:0,
        // 服务商的分页
        currentPage:1,
        total: 0,

        // 弹窗
        show:false,
        fuwushang:{},

        //派单规则
        current:null,
        showEdit:false,

        //计价公式
        showPricingformula:false,


        //地图组件延时加载
        lazyLoading:false,
        //高度
        msgData:0,
        //用户传递的经度纬度
        locationorigin:[],
        locationdestination:[],

        //服务商默认选中
        pitchon: -1,
        //服务商地下司机
        driver:[],

        //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
        map:null,

        //当前点击服务商的信息
        servicename:'',
        servicephone:'',
        servicedetailed_address:'',

        //城市列表
        city:[],
        value:'',

        // 全部服务商
        allservice:[],
        //搜索条件
        where:{
          type: 0
        },
        keyword:'',

        // 全部司机
        alldriver:[],
        //搜索条件
        where1:{
          type: 1
        },
        keyword1:'',

        //只看司机或者服务商
        sole: -1,

        // 一级code
        yijicode:'',
        // 二级code
        erjicode:'',

        sousuo:false,

        //只看司机
        checked:false,
        //只看服务商
        checked1:false,

      }
    },


      mounted() {
      //获取地图左侧内容的高度 传值给地图子组件
      // this.msgData = this.$refs.element.offsetHeight - 20;  //减去的20是内边距距离
      // //加载地图组件
      // this.lazyLoading = true;

      // 高德地图
      window._AMapSecurityConfig = {
        securityJsCode: setting.secretkey,
      }

      // 调用数据
      this.getList();

      //获取只有二级的城市
      this.getCity();

      // 默认获取全国 服务商
      this.getQuanguo();
      // 默认获取全部司机
      this.getalldriver();

      this.isShow = 0;
      //DOM初始化完成进行地图初始化
      this.initMap();
    },

    methods: {
      // 获取全国服务商 and 司机
      getQuanguo(){
        nationwide_facilitator(this.where).then(res => {
          this.allservice = res.data;
            //DOM初始化完成进行地图初始化
            this.initMap();
        })
      },
      // 获取全部司机
      getalldriver(){
        nationwide_facilitator(this.where1).then(res => {
          this.alldriver = res.data;
          this.alldriver.forEach(item => {
            item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
          });
            //DOM初始化完成进行地图初始化
            this.initMap();
        })
      },


      // 获取省市区
      getList(){
        get_range_statistics(this.where).then(res => {
          this.list = res.data;
        })
      },

      // 获取城市列表
      getCity(){
        getcityList().then(res => {
          this.city = res.data;
        })
      },

      //点击省份
      clickactive(item,index){
        console.log(item);
        this.isShow = 1;
        let where = {
          type:'1',
          code: item.value
        }
        get_range_statistics(where).then(res => {
          this.children = res.data;
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        });

        // 获取全部 司机
        this.where1.code = item.value;
        nationwide_facilitator(this.where1).then(res => {
          this.alldriver = res.data;
          this.alldriver.forEach(item => {
            item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
          });
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        })

        //全部服务商
        this.where.code = item.value;
        nationwide_facilitator(this.where).then(res => {
          this.allservice = res.data;
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        })

        //一级code
        this.yijicode = item.value;
      },

      //点击市
      changechildren(item,index){
        this.isShow = 2;
        // 获取服务商
        let where = {
          type:'2',
          code: item.value
        }
        get_range_statistics(where).then(res => {
          this.gongsiList = [];
          // 过滤数据只要有值的
          res.data.forEach(item => {
            if(item.company_name != '' || item.detailed_address != ''){
              this.gongsiList.push(item);
            }
          });
          this.total = this.gongsiList.length;
          // 重新渲染地图
          this.initMap();
          // 清除地图覆盖物
          this.map.clearMap();
        });

        // 获取全部 司机
        this.where1.code = item.value;
        nationwide_facilitator(this.where1).then(res => {
          this.driver = res.data;
          this.driver.forEach(item => {
            item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
          });
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        });

        // 二级code
        this.erjicode = item.value;
      },

      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },

      // 打开服务商详情
      openShow(item,index){
        console.log(item);
        console.log(index);
        // 显示弹窗数据
        // this.show = true;
        // 显示弹窗数据
        this.fuwushang = item;
        // 选中样式 数据
        this.pitchon = index;

        // 也显示服务商标记
        this.gongsiList = [];
        this.gongsiList.push(item);

        // // 获取服务商
        // let where1 = {
        //   type:'2',
        //   code: this.erjicode
        // }
        // get_range_statistics(where1).then(res => {
        //   this.gongsiList = [];
        //   // 过滤数据只要有值的
        //   res.data.forEach(item => {
        //     if(item.company_name != '' || item.detailed_address != ''){
        //       this.gongsiList.push(item);
        //     }
        //   });
        //   this.total = this.gongsiList.length;
        //   // 重新渲染地图
        //   this.initMap();
        //   // 清除地图覆盖物
        //   // this.map.clearMap();
        // });

        //获取该服务商下的司机数据
        let where = {
          facilitator_id: item.id
        }
        get_driver_coord(where).then(res => {
          this.driver = res.data;
          this.driver.forEach(item => {
            item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
          });
          // 清除地图覆盖物
          // this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        });
      },

      //点击返回
      returnstep(number){
        // 显示的块
        this.isShow = number;
        // 选中的服务商
        this.pitchon = -1;
        // 清除地图覆盖物
        this.map.clearMap();
        // 重新渲染地图
        this.initMap();

        // 是否搜索过
        if(this.sousuo == true){
          this.isShow = 0;
          this.keyword = '';
          this.keyword1 = '';
          delete this.where.code
          delete this.where.keyword
          //全部服务商
          nationwide_facilitator(this.where).then(res => {
            this.allservice = res.data;
            // 清除地图覆盖物
            this.map.clearMap();
            // 重新渲染地图
            this.initMap();
          })
          // 全部司机
          delete this.where1.code
          delete this.where.keyword
          nationwide_facilitator(this.where1).then(res => {
            this.alldriver = res.data;
            this.alldriver.forEach(item => {
              item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
            });
            // 清除地图覆盖物
            this.map.clearMap();
            // 重新渲染地图
            this.initMap();
          })
        }else {
          // 去掉关键字条件搜索
          if(number == 0){
            delete this.where.code
            //全部服务商
            nationwide_facilitator(this.where).then(res => {
              this.allservice = res.data;
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
            // 全部司机
            delete this.where1.code
            nationwide_facilitator(this.where1).then(res => {
              this.alldriver = res.data;
              this.alldriver.forEach(item => {
                item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
              });
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
          }else if(number == 1){
            this.where1.code = this.yijicode;
            // 获取全部 司机
            nationwide_facilitator(this.where1).then(res => {
              this.alldriver = res.data;
              this.alldriver.forEach(item => {
                item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
              });
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
            this.where1.code = this.yijicode;
            //全部服务商
            nationwide_facilitator(this.where).then(res => {
              this.allservice = res.data;
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
          }
        }

      },

      //点击只看司机 或者 只看服务商
      justsee(number){
        this.sole = number;
        if(this.sole == 0){
          nationwide_facilitator(this.where).then(res => {
            this.allservice = res.data;
            // 清除地图覆盖物
            this.map.clearMap();
            // 重新渲染地图
            this.initMap();
          })
        }else {
          nationwide_facilitator(this.where1).then(res => {
            this.alldriver = res.data;
            this.alldriver.forEach(item => {
              item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
            });
            // 清除地图覆盖物
            this.map.clearMap();
            // 重新渲染地图
            this.initMap();
          })
        }
      },

      // 搜索
      keywordquery(number){
        this.isShow = 2;
        if(number == 0){
          if(this.keyword != ''){
            this.where.keyword = this.keyword;
            //全部服务商
            nationwide_facilitator(this.where).then(res => {
              this.sousuo = true;
              this.gongsiList = res.data;
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
          }
        }else {
          if(this.keyword1 != ''){
            // 获取全部 司机
            this.keyword1 = this.keyword;
            this.where.keyword = this.keyword1;
            nationwide_facilitator(this.where1).then(res => {
              this.sousuo = true;
              this.alldriver = res.data;
              this.alldriver.forEach(item => {
                item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
              });
              // 清除地图覆盖物
              this.map.clearMap();
              // 重新渲染地图
              this.initMap();
            })
          }
        }


      },

      //派单规则
      showdetails(value){
        this.showEdit = value;
      },
      //点击设置派单规则
      openDetails(){
        this.showEdit = true;
      },

      //添加计价公式
      openPricingformula(){
        this.showPricingformula = true;
      },
      showpricingformula(value){
        this.showPricingformula = value;
      },

      //选择顶部地区
      handleChange(value){
        console.log(value)
        // 获取全部 司机
        this.where1.code = value[0];
        nationwide_facilitator(this.where1).then(res => {
          this.alldriver = res.data;
          this.alldriver.forEach(item => {
            item.icon = 'http://saas_api.bxxt.cn/icon/20231023095717.png'
          });
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        })

        //全部服务商
        this.where.code = value[0];
        nationwide_facilitator(this.where).then(res => {
          this.allservice = res.data;
          // 清除地图覆盖物
          this.map.clearMap();
          // 重新渲染地图
          this.initMap();
        })
      },

      // 初始化高德地图
      initMap(){
        AMapLoader.load({
          key: setting.key, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Driving','AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            mapStyle: "amap://styles/grey",
            zoom: 13,
            center: [116.397428, 39.90923],//地图中心点
            features: ['bg', 'road', 'building', 'point'],
            resizeEnable: true
          });


          // 默认获取 全国
          if(this.isShow == 0){
            if(this.sole == 1 || this.sole == -1){
              // 司机
              var markers = this.alldriver;
              // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
              markers.forEach(marker => {
                console.log(marker);
                var marker = new AMap.Marker({
                  map: this.map,
                  icon: marker.icon,
                  position: [marker.longitude, marker.latitude],
                  offset: new AMap.Pixel(-13, -30),
                  name: marker.realname,
                  // 车牌号
                  license_plate_number: marker.license_plate_number,
                  // 服务里程
                  accumulated_mileage: marker.accumulated_mileage,
                  // 位置更新
                  coord_update_time:marker.coord_update_time,
                  // 在线时长
                  cumulative_online:marker.cumulative_online,
                  // 电话
                  phone: marker.phone,
                  // 在线状态
                  on_line_name: marker.on_line_name,
                  // 所属公司
                  company_name: marker.company_name
                });
                // 点标记 点击事件
                marker.on('click', (e) => {
                  console.log(e);
                  // 窗口信息
                  const infoWindow = new AMap.InfoWindow({
                    isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                    closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                    content: `<div style="width:300px; background: #FFFFFF;padding: 20px;"><div style="display: flex;justify-content: space-between;align-items: center; margin-bottom: 10px;"><span><i class="el-icon-user-solid"></i><span>-</span><span style="margin-left: 8px;">` + e.target._originOpts.on_line_name + `</span></span><span>` + e.target._originOpts.license_plate_number + `</span></div><div style="padding-bottom: 10px; border-bottom: 1px dashed #409EFF;">` + e.target._originOpts.company_name + `</div><div style="margin-top: 10px;"><span>联系电话：</span><span>` + e.target._originOpts.phone + `</span></div><div style="margin-top: 10px;"><span>在线时长：</span><span>` + e.target._originOpts.cumulative_online + `</span></div><div style="margin-top: 10px;"><span>服务里程：</span><span>` + e.target._originOpts.accumulated_mileage + `</span></div><div style="margin-top: 10px;"><span>位置更新：</span><span>` + e.target._originOpts.coord_update_time + `</span></div><div style="margin-top:15px;"><div id="guanbi" ref="chuangtidiv" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 8px 10px 8px 10px;width: 50%;margin: auto;cursor: pointer;">关闭窗口</div></div></div>`,
                    // content: `<div style="background-color: red;width: 80px;height: 80px;border-radius: 6px;line-height: 80px;text-align: center;font-size:12px;">132465</div>`,
                    offset: new AMap.Pixel(0,-15)
                  });
                  // 打开窗体
                  infoWindow.open(this.map,
                    // 窗口信息的位置
                    marker.getPosition(e.target._position[0],e.target._position[1])
                  );
                  //关闭窗口信息
                  var closeX = document.getElementById('guanbi');
                  closeX.onclick = closeInfoWindow;
                  //关闭信息窗体
                  function closeInfoWindow() {
                    // this.map.clearInfoWindow();
                    // 关闭信息窗体
                    infoWindow.close();
                  }

                });
              });
              var center = this.map.getCenter();
              var setFitViewBtn = document.getElementById('setFitView');
              this.map.setFitView(null, false, [150, 60, 100, 60]);
              var newCenter = this.map.getCenter();
            }


            // 服务商
           if(this.sole == 0 || this.sole == -1){
             var markers1 = this.allservice;
             // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
             markers1.forEach(marker => {
               console.log(marker);
               var marker1 = new AMap.Marker({
                 map: this.map,
                 position: [marker.longitude, marker.latitude],
                 offset: new AMap.Pixel(-13, -30),
                 name: marker.company_name,
                 phone: marker.phone,
                 detailed_address: marker.detailed_address
               });
               // 点标记 点击事件
               marker1.on('click', (e) => {
                 console.log(e);
                 // 窗口信息
                 const infoWindow1 = new AMap.InfoWindow({
                   isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                   closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                   content: `<div style="width: 500px;">
                    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">
                      <span style="font-size: 18px;font-weight: 500;color: #ffffff;"> ` + e.target._originOpts.name + ` </span>
                    </div>
                    <div style="background:rgba(127,127,127,0.8);padding: 20px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
                      <div>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.phone + `</span>
                      </div>
                      <div style="margin-top: 10px;">
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.detailed_address + `</span>
                      </div>
                      <div style="margin-top: 20px;text-align: center;display: flex;justify-content: space-between;">
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">添加计价公式</div>-->
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">设置接单规则</div>-->
                        <div style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto; cursor: pointer;" id="guanbi1">关闭</div>
                      </div>
                    </div>
                </div>`,
                   offset: new AMap.Pixel(0,-15)
                 });
                 // 打开窗体
                 infoWindow1.open(this.map,
                   // 窗口信息的位置
                   marker1.getPosition(e.target._position[0],e.target._position[1])
                 );
                 //关闭窗口信息
                 var closeX1 = document.getElementById('guanbi1');
                 closeX1.onclick = closeInfoWindow1;
                 //关闭信息窗体
                 function closeInfoWindow1() {
                   console.log('点击了关闭')
                   // this.map.clearInfoWindow();
                   // 关闭信息窗体
                   infoWindow1.close();
                 }
               });
             });
             var center1 = this.map.getCenter();
             var setFitViewBtn1 = document.getElementById('setFitView');
             this.map.setFitView(null, false, [150, 60, 100, 60]);
             var newCenter1 = this.map.getCenter();
           }
          }


          // 获取市级全国
          if(this.isShow == 1){
            if(this.sole == 1 || this.sole == -1){
              // 司机
              var markers = this.alldriver;
              // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
              markers.forEach(marker => {
                console.log(marker);
                var marker = new AMap.Marker({
                  map: this.map,
                  icon: marker.icon,
                  position: [marker.longitude, marker.latitude],
                  offset: new AMap.Pixel(-13, -30),
                  name: marker.realname,
                  // 车牌号
                  license_plate_number: marker.license_plate_number,
                  // 服务里程
                  accumulated_mileage: marker.accumulated_mileage,
                  // 位置更新
                  coord_update_time:marker.coord_update_time,
                  // 在线时长
                  cumulative_online:marker.cumulative_online,
                  // 电话
                  phone: marker.phone,
                  // 在线状态
                  on_line_name: marker.on_line_name,
                  // 所属公司
                  company_name: marker.company_name
                });
                // 点标记 点击事件
                marker.on('click', (e) => {
                  console.log(e);
                  // 窗口信息
                  const infoWindow = new AMap.InfoWindow({
                    isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                    closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                    content: `<div style="width:300px; background: #FFFFFF;padding: 20px;"><div style="display: flex;justify-content: space-between;align-items: center; margin-bottom: 10px;"><span><i class="el-icon-user-solid"></i><span>-</span><span style="margin-left: 8px;">` + e.target._originOpts.on_line_name + `</span></span><span>` + e.target._originOpts.license_plate_number + `</span></div><div style="padding-bottom: 10px; border-bottom: 1px dashed #409EFF;">` + e.target._originOpts.company_name + `</div><div style="margin-top: 10px;"><span>联系电话：</span><span>` + e.target._originOpts.phone + `</span></div><div style="margin-top: 10px;"><span>在线时长：</span><span>` + e.target._originOpts.cumulative_online + `</span></div><div style="margin-top: 10px;"><span>服务里程：</span><span>` + e.target._originOpts.accumulated_mileage + `</span></div><div style="margin-top: 10px;"><span>位置更新：</span><span>` + e.target._originOpts.coord_update_time + `</span></div><div style="margin-top:15px;"><div id="guanbi" ref="chuangtidiv" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 8px 10px 8px 10px;width: 50%;margin: auto;cursor: pointer;">关闭窗口</div></div></div>`,
                    // content: `<div style="background-color: red;width: 80px;height: 80px;border-radius: 6px;line-height: 80px;text-align: center;font-size:12px;">132465</div>`,
                    offset: new AMap.Pixel(0,-15)
                  });
                  // 打开窗体
                  infoWindow.open(this.map,
                    // 窗口信息的位置
                    marker.getPosition(e.target._position[0],e.target._position[1])
                  );
                  //关闭窗口信息
                  var closeX = document.getElementById('guanbi');
                  closeX.onclick = closeInfoWindow;
                  //关闭信息窗体
                  function closeInfoWindow() {
                    // this.map.clearInfoWindow();
                    // 关闭信息窗体
                    infoWindow.close();
                  }

                });
              });
              var center = this.map.getCenter();
              var setFitViewBtn = document.getElementById('setFitView');
              this.map.setFitView(null, false, [150, 60, 100, 60]);
              var newCenter = this.map.getCenter();
            }

            // 服务商
            if(this.sole == 0 || this.sole == -1){
              var markers1 = this.allservice;
              // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
              markers1.forEach(marker => {
                console.log(marker);
                var marker1 = new AMap.Marker({
                  map: this.map,
                  position: [marker.longitude, marker.latitude],
                  offset: new AMap.Pixel(-13, -30),
                  name: marker.company_name,
                  phone: marker.phone,
                  detailed_address: marker.detailed_address
                });
                // 点标记 点击事件
                marker1.on('click', (e) => {
                  console.log(e);
                  // 窗口信息
                  const infoWindow1 = new AMap.InfoWindow({
                    isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                    closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                    content: `<div style="width: 500px;">
                    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">
                      <span style="font-size: 18px;font-weight: 500;color: #ffffff;"> ` + e.target._originOpts.name + ` </span>
                    </div>
                    <div style="background:rgba(127,127,127,0.8);padding: 20px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
                      <div>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.phone + `</span>
                      </div>
                      <div style="margin-top: 10px;">
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.detailed_address + `</span>
                      </div>
                      <div style="margin-top: 20px;text-align: center;display: flex;justify-content: space-between;">
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">添加计价公式</div>-->
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">设置接单规则</div>-->
                        <div style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto; cursor: pointer;" id="guanbi1">关闭</div>
                      </div>
                    </div>
                </div>`,
                    offset: new AMap.Pixel(0,-15)
                  });
                  // 打开窗体
                  infoWindow1.open(this.map,
                    // 窗口信息的位置
                    marker1.getPosition(e.target._position[0],e.target._position[1])
                  );
                  //关闭窗口信息
                  var closeX1 = document.getElementById('guanbi1');
                  closeX1.onclick = closeInfoWindow1;
                  //关闭信息窗体
                  function closeInfoWindow1() {
                    console.log('点击了关闭')
                    // this.map.clearInfoWindow();
                    // 关闭信息窗体
                    infoWindow1.close();
                  }
                });
              });
              var center1 = this.map.getCenter();
              var setFitViewBtn1 = document.getElementById('setFitView');
              this.map.setFitView(null, false, [150, 60, 100, 60]);
              var newCenter1 = this.map.getCenter();
            }
          }


          // 判断点击了服务商再渲染 司机 也渲染下服务商
          if(this.isShow == 2 && this.pitchon != -1){
            // 司机
            var markers = this.driver;
            // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
            markers.forEach(marker => {
              console.log(marker);
              var marker = new AMap.Marker({
                map: this.map,
                icon: marker.icon,
                position: [marker.longitude, marker.latitude],
                offset: new AMap.Pixel(-13, -30),
                name: marker.realname,
                // 车牌号
                license_plate_number: marker.license_plate_number,
                // 服务里程
                accumulated_mileage: marker.accumulated_mileage,
                // 位置更新
                coord_update_time:marker.coord_update_time,
                // 在线时长
                cumulative_online:marker.cumulative_online,
                // 电话
                phone: marker.phone,
                // 在线状态
                on_line_name: marker.on_line_name,
                // 所属公司
                company_name: marker.company_name
              });
              // 点标记 点击事件
              marker.on('click', (e) => {
                console.log(e);
                // 窗口信息
                const infoWindow = new AMap.InfoWindow({
                  isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                  closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                  content: `<div style="width:300px; background: #FFFFFF;padding: 20px;"><div style="display: flex;justify-content: space-between;align-items: center; margin-bottom: 10px;"><span><i class="el-icon-user-solid"></i><span>-</span><span style="margin-left: 8px;">` + e.target._originOpts.on_line_name + `</span></span><span>` + e.target._originOpts.license_plate_number + `</span></div><div style="padding-bottom: 10px; border-bottom: 1px dashed #409EFF;">` + e.target._originOpts.company_name + `</div><div style="margin-top: 10px;"><span>联系电话：</span><span>` + e.target._originOpts.phone + `</span></div><div style="margin-top: 10px;"><span>在线时长：</span><span>` + e.target._originOpts.cumulative_online + `</span></div><div style="margin-top: 10px;"><span>服务里程：</span><span>` + e.target._originOpts.accumulated_mileage + `</span></div><div style="margin-top: 10px;"><span>位置更新：</span><span>` + e.target._originOpts.coord_update_time + `</span></div><div style="margin-top:15px;"><div id="guanbi" ref="chuangtidiv" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 8px 10px 8px 10px;width: 50%;margin: auto;cursor: pointer;">关闭窗口</div></div></div>`,
                  // content: `<div style="background-color: red;width: 80px;height: 80px;border-radius: 6px;line-height: 80px;text-align: center;font-size:12px;">132465</div>`,
                  offset: new AMap.Pixel(0,-15)
                });
                // 打开窗体
                infoWindow.open(this.map,
                  // 窗口信息的位置
                  marker.getPosition(e.target._position[0],e.target._position[1])
                );
                //关闭窗口信息
                var closeX = document.getElementById('guanbi');
                closeX.onclick = closeInfoWindow;
                //关闭信息窗体
                function closeInfoWindow() {
                  // this.map.clearInfoWindow();
                  // 关闭信息窗体
                  infoWindow.close();
                }

              });
            });
            var center = this.map.getCenter();
            var setFitViewBtn = document.getElementById('setFitView');
            this.map.setFitView(null, false, [150, 60, 100, 60]);
            var newCenter = this.map.getCenter();

            // 服务商
            var markers1 = this.gongsiList;
            // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
            markers1.forEach(marker => {
              console.log(marker);
              var marker1 = new AMap.Marker({
                map: this.map,
                position: [marker.longitude, marker.latitude],
                offset: new AMap.Pixel(-13, -30),
                name: marker.company_name,
                phone: marker.phone,
                detailed_address: marker.detailed_address
              });
              // 点标记 点击事件
              marker1.on('click', (e) => {
                console.log(e);
                // 窗口信息
                const infoWindow1 = new AMap.InfoWindow({
                  isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                  closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                  content: `<div style="width: 500px;">
                    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">
                      <span style="font-size: 18px;font-weight: 500;color: #ffffff;"> ` + e.target._originOpts.name + ` </span>
                    </div>
                    <div style="background:rgba(127,127,127,0.8);padding: 20px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
                      <div>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.phone + `</span>
                      </div>
                      <div style="margin-top: 10px;">
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.detailed_address + `</span>
                      </div>
                      <div style="margin-top: 20px;text-align: center;display: flex;justify-content: space-between;">
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">添加计价公式</div>-->
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">设置接单规则</div>-->
                        <div style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto; cursor: pointer;" id="guanbi1">关闭</div>
                      </div>
                    </div>
                </div>`,
                  offset: new AMap.Pixel(0,-15)
                });
                // 打开窗体
                infoWindow1.open(this.map,
                    // 窗口信息的位置
                    marker1.getPosition(e.target._position[0],e.target._position[1])
                );
                //关闭窗口信息
                var closeX1 = document.getElementById('guanbi1');
                closeX1.onclick = closeInfoWindow1;
                //关闭信息窗体
                function closeInfoWindow1() {
                  console.log('点击了关闭')
                  // this.map.clearInfoWindow();
                  // 关闭信息窗体
                  infoWindow1.close();
                }
              });
            });
            var center1 = this.map.getCenter();
            var setFitViewBtn1 = document.getElementById('setFitView');
            this.map.setFitView(null, false, [150, 60, 100, 60]);
            var newCenter1 = this.map.getCenter();

          }

          // 判断点击了服务商再渲染 服务商
          if(this.isShow == 2 && this.pitchon == -1){
            var markers1 = this.gongsiList;
            // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
            markers1.forEach(marker => {
              console.log(marker);
              var marker1 = new AMap.Marker({
                map: this.map,
                position: [marker.longitude, marker.latitude],
                offset: new AMap.Pixel(-13, -30),
                name: marker.company_name,
                phone: marker.phone,
                detailed_address: marker.detailed_address
              });
              // 点标记 点击事件
              marker1.on('click', (e) => {
                console.log(e);
                // 窗口信息
                const infoWindow1 = new AMap.InfoWindow({
                  isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                  closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                  content: `<div style="width: 500px;">
                    <div style="background: #FEA737;text-align: center;padding-top: 10px;padding-bottom: 10px;border-top-left-radius: 6px;border-top-right-radius: 6px;display: flex;align-items: center;justify-content: center;">
                      <span style="font-size: 18px;font-weight: 500;color: #ffffff;"> ` + e.target._originOpts.name + ` </span>
                    </div>
                    <div style="background:rgba(127,127,127,0.8);padding: 20px;border-bottom-left-radius: 6px;border-bottom-right-radius: 6px;">
                      <div>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">联系电话：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.phone + `</span>
                      </div>
                      <div style="margin-top: 10px;">
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">所在区域：</span>
                        <span style="font-size: 14px;font-weight: 400;color: #ffffff;">` + e.target._originOpts.detailed_address + `</span>
                      </div>
                      <div style="margin-top: 20px;text-align: center;display: flex;justify-content: space-between;">
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">添加计价公式</div>-->
                        <!--<div style="background: #FEA837;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto;">设置接单规则</div>-->
                        <div style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 5px 10px 5px 10px;width:30%;margin: auto; cursor: pointer;" id="guanbi1">关闭</div>
                      </div>
                    </div>
                </div>`,
                  offset: new AMap.Pixel(0,-15)
                });
                // 打开窗体
                infoWindow1.open(this.map,
                  // 窗口信息的位置
                  marker1.getPosition(e.target._position[0],e.target._position[1])
                );
                //关闭窗口信息
                var closeX1 = document.getElementById('guanbi1');
                closeX1.onclick = closeInfoWindow1;
                //关闭信息窗体
                function closeInfoWindow1() {
                  console.log('点击了关闭')
                  // this.map.clearInfoWindow();
                  // 关闭信息窗体
                  infoWindow1.close();
                }
              });
            });
            var center1 = this.map.getCenter();
            var setFitViewBtn1 = document.getElementById('setFitView');
            this.map.setFitView(null, false, [150, 60, 100, 60]);
            var newCenter1 = this.map.getCenter();

            var markers = this.driver;
            // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
            markers.forEach(marker => {
              console.log(marker);
              var marker = new AMap.Marker({
                map: this.map,
                icon: marker.icon,
                position: [marker.longitude, marker.latitude],
                offset: new AMap.Pixel(-13, -30),
                name: marker.realname,
                // 车牌号
                license_plate_number: marker.license_plate_number,
                // 服务里程
                accumulated_mileage: marker.accumulated_mileage,
                // 位置更新
                coord_update_time:marker.coord_update_time,
                // 在线时长
                cumulative_online:marker.cumulative_online,
                // 电话
                phone: marker.phone,
                // 在线状态
                on_line_name: marker.on_line_name,
                // 所属公司
                company_name: marker.company_name
              });
              // 点标记 点击事件
              marker.on('click', (e) => {
                console.log(e);
                // 窗口信息
                const infoWindow = new AMap.InfoWindow({
                  isCustom: true, // 自定义窗口,窗口外框以及内容完全按照content所设的值添加
                  closeWhenClickMap: true, // 是否在鼠标点击地图关闭窗口
                  content: `<div style="width:300px; background: #FFFFFF;padding: 20px;"><div style="display: flex;justify-content: space-between;align-items: center; margin-bottom: 10px;"><span><i class="el-icon-user-solid"></i><span>-</span><span style="margin-left: 8px;">` + e.target._originOpts.on_line_name + `</span></span><span>` + e.target._originOpts.license_plate_number + `</span></div><div style="padding-bottom: 10px; border-bottom: 1px dashed #409EFF;">` + e.target._originOpts.company_name + `</div><div style="margin-top: 10px;"><span>联系电话：</span><span>` + e.target._originOpts.phone + `</span></div><div style="margin-top: 10px;"><span>在线时长：</span><span>` + e.target._originOpts.cumulative_online + `</span></div><div style="margin-top: 10px;"><span>服务里程：</span><span>` + e.target._originOpts.accumulated_mileage + `</span></div><div style="margin-top: 10px;"><span>位置更新：</span><span>` + e.target._originOpts.coord_update_time + `</span></div><div style="margin-top:15px;"><div id="guanbi" ref="chuangtidiv" style="background: #FF7D7D;color: #FFFFFF;text-align: center; padding: 8px 10px 8px 10px;width: 50%;margin: auto;cursor: pointer;">关闭窗口</div></div></div>`,
                  // content: `<div style="background-color: red;width: 80px;height: 80px;border-radius: 6px;line-height: 80px;text-align: center;font-size:12px;">132465</div>`,
                  offset: new AMap.Pixel(0,-15)
                });
                // 打开窗体
                infoWindow.open(this.map,
                  // 窗口信息的位置
                  marker.getPosition(e.target._position[0],e.target._position[1])
                );
                //关闭窗口信息
                var closeX = document.getElementById('guanbi');
                closeX.onclick = closeInfoWindow;
                //关闭信息窗体
                function closeInfoWindow() {
                  // this.map.clearInfoWindow();
                  // 关闭信息窗体
                  infoWindow.close();
                }

              });
            });
            var center = this.map.getCenter();
            var setFitViewBtn = document.getElementById('setFitView');
            this.map.setFitView(null, false, [150, 60, 100, 60]);
            var newCenter = this.map.getCenter();

          }



        }).catch((e) => {
          console.log(e);
        });

      },

    }

  }
</script>

<style lang="scss" scoped>
  #container{
    padding:0px;
    margin: 0px;
    width: 100%;
    //min-height: 300px;
    //max-height: 750px;
    height: 100%;
  }
  .yunlitongjicontent{
    width: 100%;
    position: absolute;
    top: 20px;
    padding: 20px;
  }
  .content1yunlitongji{
    display: flex;
    align-items: center;
  }
  .content_inputyunlitongji{
    display: inline-block;
    border-radius: 10px;
    //background: #FFFFFF;
    padding: 10px;
    .el-input__inner{
      border: none !important;
    }
  }
  .content_input1yunlitongji{
    //width: 140px;
    display: inline-block;
    border-radius: 10px;
    //background: #FFFFFF;
    //padding: 10px;
    //line-height: 36px;
    margin-left: 20px;
    cursor: pointer;
  }

  .content_input5yunlitongji{
    width: 200px;
    display: inline-block;
    border-radius: 10px;
    //background: #FFFFFF;
    padding: 10px;
    line-height: 36px;
    margin-left: 20px;
    cursor: pointer;
    .el-input__inner{
      border-color: #FFFFFF !important;
    }
  }

  .content2{
    width: 20%;
    padding: 20px;
    background: #FFFFFF;
    margin-top: 20px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 787px;
    overflow: scroll;
    position: absolute;
    top: 90px;
    left: 20px;
  }
  .content3{
    width: 28%;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 787px;
    overflow: scroll;
    position: absolute;
    top: 110px;
    left: 20px;
  }
  .fontcolor{
    font-size: 14px;
    font-weight: 400;
    color: #7e7f8c;
  }
  .fontcolor1{
    color: #FF9B05;
  }

  .shoufengqin{
    .el-collapse{
      border: none !important;
    }
    .el-collapse-item__wrap{
      border: none !important;
    }
    .el-collapse-item__header{
      border: none !important;
    }
    .el-collapse-item__header.is-active{
      border: none !important;
      color: #FF9B05 !important;
    }
  }

  .xuanze{
    margin-bottom: 10px;
    cursor: pointer;
    //文字超出显示...
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .xuanze:hover{
    color: #FF9B05;
  }

  .gongsiliebiao{
    padding: 10px;
    border: 1px solid #EEEEEE;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .gongsiliebiao:hover{
    background: #FFF4E3;
    color: #FF9B05;
    border-color: #ffd38e;
  }
  .gongsiliebiao1{
    padding: 10px;
    border: 1px solid #ffd38e;
    margin-bottom: 10px;
    cursor: pointer;
    background: #FFF4E3;
    color: #FF9B05;
  }

  .tanchuang{
    width: 25%;
    position: absolute;
    top: 30%;
    left: 40%;
    margin: -50px 0 0 -50px;
  }
  .tanchuang_b{
    background:rgba(127,127,127,0.8);
    padding: 20px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
</style>
